import { Clerk } from "@clerk/clerk-js";
import { createApp } from 'vue'

const redirectUrl = `https://${process.env.APPDOMAIN}/dashboard/`;

async function main() {
  const app = createApp({
    data() {
      return {
        clerk: undefined,
        clerkUser: undefined,
        isReady: false
      }
    },
    computed: {
      // a computed getter
      isLoggedIn() {
        // `this` points to the component instance
        return this.clerkUser !== undefined && this.clerkUser !== null
      }
    }
  }).mount('#app')

  const clerkPubKey = process.env.VITE_CLERK_PUBLISHABLE_KEY;

  const clerk = app.clerk = new Clerk(clerkPubKey);
  await clerk.load({
    // Set load options here
  });

  app.isReady = true;

  if (clerk.user) {
    app.clerkUser = clerk.user;
    const btnUser = document.getElementById("user")
    clerk.mountUserButton(btnUser);
  }
  else {
    const btnSignin = document.getElementById("signinButton")

    const divSignin = document.getElementById("signin")
    const divSignup = document.getElementById("signup")
    const divPlan = document.getElementById("plan")
    btnSignin.addEventListener('click', function() {
      document.getElementById("signinWrapper").classList.remove('hidden');
      divSignin.classList.remove('hidden')
      divSignup.classList.add('hidden')
      divPlan.classList.add('hidden')
      clerk.mountSignIn(divSignin, {
        // TODO: These redirect Urls worked before, but not any longer. Urls are correct.
        forceRedirectUrl: redirectUrl,
        signInForceRedirectUrl: redirectUrl,
        signUpForceRedirectUrl: redirectUrl
      });
    })
    const btnSignup = document.getElementById("signupButton")
    btnSignup.addEventListener('click', function() {
      document.getElementById("signinWrapper").classList.remove('hidden');
      divSignin.classList.add('hidden')
      divSignup.classList.add('hidden')
      divPlan.classList.remove('hidden')
    })
    function addEventListenerPlan(name) {
      const freePlanButtons = document.getElementsByClassName(`plan-${name}`);
      for (let i = 0; i < freePlanButtons.length; i++) {
        freePlanButtons[i].addEventListener('click', function() {
          localStorage.setItem('planChosen', `${name}`);
          divPlan.classList.add('hidden')
          divSignup.classList.remove('hidden')
          clerk.mountSignUp(divSignup, {
          // TODO: These redirect Urls worked before, but not any longer. Urls are correct.
            forceRedirectUrl: redirectUrl,
            signInForceRedirectUrl: redirectUrl,
            signUpForceRedirectUrl: redirectUrl
          });
        })
      }
    }
    addEventListenerPlan('free')
    addEventListenerPlan('creator')
    addEventListenerPlan('pro')
  }
}
main();
